<template>
  <div class="cardInfos" v-if="!isFiltering">
    <template v-if="!isTerritorio">
      <div v-if="(isSala && !config.visualizarPontuacaoSelo) || !isSala">
        <div class="corPadrao" style="background: #b9e7f8"></div>
        {{
          isSala
            ? "Municípios com parceiros participantes"
            : "Destacando municípios vigentes"
        }}
      </div>

      <div v-if="!isSala" class="mt-2">
        menos pontos
        <div class="corPadrao" style="background: #b9e7f8"></div>
        <v-icon style="transform: rotate(180deg)"
          >mdi-keyboard-backspace</v-icon
        >
        <div class="corPadrao" style="background: #09344e"></div>
        mais pontos.
      </div>
    </template>
    <div v-if="isSala && config.visualizarPontuacaoSelo" class="mt-2 part">
      <div>Participantes premiados</div>
      <div class="d-flex cores">
        <div class="d-flex mr-1">
          <div class="corPadrao mr-1" style="background: #b9e7f8"></div>
          Sem Selo
        </div>
        <div class="d-flex mr-1">
          <div class="corPadrao mr-1" style="background: #c66b3fb5"></div>
          Bronze
        </div>
        <div class="d-flex mr-1">
          <div
            class="corPadrao mr-1"
            style="background: rgb(145 145 145)"
          ></div>
          Prata
        </div>
        <div class="d-flex">
          <div class="corPadrao mr-1" style="background: #f8c33b"></div>
          Ouro
        </div>
      </div>
    </div>
    <div v-if="isTerritorio" class="legendaTerritorios">
      <div class="tiposLegendas">
        <div class="corPadrao possuiCE"></div>
        Possui Cidade Empreendedora
      </div>
      <div class="tiposLegendas">
        <div class="corPadrao possuiTE"></div>
        Possui Território Empreendedor
      </div>
      <div class="tiposLegendas">
        <div class="corPadrao possuiCETE"></div>
        Possui CE e TE
      </div>
    </div>
  </div>
  <v-row v-else>
    <v-col cols="12">
      <div class="filtersColours" v-if="showAllLegends">
        <h1 @click="showAllLegends = false">
          {{
            isSala
              ? "Parceiros"
              : isTerritorio
              ? "Municípios por território"
              : "Municípios"
          }}

          <img
            style="width: 13px; margin-left: 2rem; transform: rotate(179deg)"
            :src="'/assets/img/expand1.png'"
          />
        </h1>
        <div class="wrapper-filtros-opcoes">
          <div v-if="mapa.totalMapaUfs" class="filtrosOpcoes">
            <div class="titlesFiltros">
              <div class="corUF">
                {{ mapa.totalMapaUfs }}
              </div>
              <h1>UF</h1>
            </div>
            <div class="optionsTema">
              <div v-for="uf in filter.uf" :key="uf.uf">
                <div v-if="!uf.headers"><span>-</span> {{ uf.uf }}</div>
              </div>
            </div>
          </div>
          <div v-if="mapa.totalMapaUrs" class="filtrosOpcoes">
            <div class="titlesFiltros">
              <div class="corUR">
                {{ mapa.totalMapaUrs }}
              </div>
              <h1>UR</h1>
            </div>
            <div class="optionsTema">
              <div v-for="ur in filter.ur" :key="ur.id_unidade_regional">
                <div><span>-</span> {{ ur.nomeRegiao }}</div>
              </div>
            </div>
          </div>
          <div v-if="mapa.totalMapaRelacionamentos" class="filtrosOpcoes">
            <div class="titlesFiltros">
              <div class="corRelacionamento">
                {{ mapa.totalMapaRelacionamentos }}
              </div>
              <h1>Relacionamento</h1>
            </div>
            <div class="optionsTema">
              <div
                v-for="status in filter.relacionamentos"
                :key="status.statusId"
              >
                <span>-</span> {{ status.statusNome }}
              </div>
            </div>
          </div>
          <div v-if="mapa.totalMapaEixos" class="filtrosOpcoes">
            <div class="titlesFiltros">
              <div class="corEixo">
                {{ mapa.totalMapaEixos }}
              </div>
              <h1>Eixo</h1>
            </div>
            <div class="optionsTema">
              <div v-for="eixo in filter.eixo" :key="eixo.statusContratoId">
                <span>-</span> {{ eixo.nomeTema }}
              </div>
            </div>
          </div>
          <div v-if="mapa.totalMapaIndicadores" class="filtrosOpcoes">
            <div class="titlesFiltros">
              <div class="corIndicador">
                {{ mapa.totalMapaIndicadores }}
              </div>
              <h1>{{ isSala ? "Critério" : "Indicador" }}</h1>
            </div>
            <div class="optionsTema">
              <div v-for="(indicador, index) in filter.indicador" :key="index">
                <span>-</span> {{ indicador.textoCompleto }}
              </div>
            </div>
          </div>

          <div v-if="mapa.totalMapaMunicipio" class="filtrosOpcoes">
            <div class="titlesFiltros">
              <div class="corMunicipio">
                {{ mapa.totalMapaMunicipio }}
              </div>
              <h1>{{ filter.municipio.nome }}</h1>
            </div>
          </div>

          <div class="filtrosOpcoes" v-if="mapa.totalMapaTerritorio">
            <div class="titlesFiltros">
              <div class="corUR">
                {{ mapa.totalMapaTerritorio }}
              </div>
              <h1>Municípios por território</h1>
            </div>
            <div class="optionsTema">
              <div
                v-for="(territorio, index) in filter.territorio"
                :key="index"
              >
                <span>-</span> {{ territorio.nome }}
              </div>
            </div>
          </div>

          <div class="filtrosOpcoes" v-if="mapa.totalMapaTerCategoria">
            <div class="titlesFiltros">
              <div class="corEixo">
                {{ mapa.totalMapaTerCategoria }}
              </div>
              <h1>Categorias</h1>
            </div>
            <div class="optionsTema">
              <div
                v-for="(categoria, index) in filter.terCategoria"
                :key="index"
              >
                <span>-</span> {{ categoria.nomeCategoria }}
              </div>
            </div>
          </div>

          <div class="filtrosOpcoes" v-if="mapa.totalMapaTerStatus">
            <div class="titlesFiltros">
              <div class="corRelacionamento">
                {{ mapa.totalMapaTerStatus }}
              </div>
              <h1>Status</h1>
            </div>
            <div class="optionsTema">
              <div v-for="(status, index) in filter.terStatus" :key="index">
                <span>-</span> {{ status.nome }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-col>
    <div class="legendasReduzidas" v-if="!showAllLegends">
      <div class="legTitle" @click="showAllLegends = true">
        {{
          isSala
            ? "Parceiros"
            : isTerritorio
            ? "Municípios por território"
            : "Municípios"
        }}

        <img
          style="width: 13px; margin-left: 2rem"
          :src="'/assets/img/expand1.png'"
        />
      </div>
      <div class="filtrosOpcoes" v-if="mapa.totalMapaUfs">
        <div class="titlesFiltros">
          <div class="corUF">
            {{ mapa.totalMapaUfs }}
          </div>
          <h1>UF</h1>
        </div>
      </div>
      <div class="filtrosOpcoes" v-if="mapa.totalMapaUrs">
        <div class="titlesFiltros">
          <div class="corUR">
            {{ mapa.totalMapaUrs }}
          </div>
          <h1>UR</h1>
        </div>
      </div>

      <div class="filtrosOpcoes" v-if="mapa.totalMapaRelacionamentos">
        <div class="titlesFiltros">
          <div class="corRelacionamento">
            {{ mapa.totalMapaRelacionamentos }}
          </div>
          <h1>Relacionamento</h1>
        </div>
      </div>

      <div class="filtrosOpcoes" v-if="mapa.totalMapaEixos">
        <div class="titlesFiltros">
          <div class="corEixo">
            {{ mapa.totalMapaEixos }}
          </div>
          <h1>Eixo</h1>
        </div>
      </div>
      <div class="filtrosOpcoes" v-if="mapa.totalMapaIndicadores">
        <div class="titlesFiltros">
          <div class="corIndicador">
            {{ mapa.totalMapaIndicadores }}
          </div>
          <h1>{{ isSala ? "Critério" : "Indicador" }}</h1>
        </div>
      </div>
      <div class="filtrosOpcoes" v-if="mapa.totalMapaMunicipio">
        <div class="titlesFiltros">
          <div class="corMunicipio">
            {{ mapa.totalMapaMunicipio }}
          </div>
          <h1>{{ filter.municipio.nome }}</h1>
        </div>
      </div>
      <div class="filtrosOpcoes" v-if="mapa.totalMapaTerritorio">
        <div class="titlesFiltros">
          <div class="corUR">
            {{ mapa.totalMapaTerritorio }}
          </div>
          <h1>Municípios por território</h1>
        </div>
      </div>
      <div class="filtrosOpcoes" v-if="mapa.totalMapaTerCategoria">
        <div class="titlesFiltros">
          <div class="corEixo">
            {{ mapa.totalMapaTerCategoria }}
          </div>
          <h1>Categorias</h1>
        </div>
      </div>
      <div class="filtrosOpcoes" v-if="mapa.totalMapaTerStatus">
        <div class="titlesFiltros">
          <div class="corRelacionamento">
            {{ mapa.totalMapaTerStatus }}
          </div>
          <h1>Status</h1>
        </div>
      </div>
    </div>
  </v-row>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "LegendaMapa",
  computed: {
    ...mapGetters(["isSala", "config", "isTerritorio", "mapa"]),
    isFiltering() {
      delete this.mapa.filter.eixoTipo;

      var a = Object.values(this.mapa.filter).some((val) => {
        if (Array.isArray(val)) return val.length > 0;
        if (typeof val == "object") return Object.keys(val).length > 0;

        return val;
      });

      return a;
    },
    filter() {
      return this.mapa.filter;
    },
  },

  data() {
    return {
      showAllLegends: false,
    };
  },
};
</script>
<style lang="scss" scoped>
.legendaTerritorios {
  flex-direction: column;

  .tiposLegendas {
    display: flex;
    column-gap: 10px;
  }
}
</style>
