<template>
  <div :class="`filters ${showFilters ? 'showFilters' : ''} `">
    <v-btn
      v-if="!showFilters"
      @click="showFilters = true"
      style="background: #086a87; color: #fff"
    >
      Filtros

      <span style="margin-left: 1rem">
        <img style="width: 10px" src="/assets/img/add.svg" />
      </span>
    </v-btn>
    <v-btn class="closeFilters" v-if="showFilters" @click="showFilters = false">
      <span>
        <img style="width: 20px" src="/assets/img/grey_close.svg" />
      </span>
    </v-btn>

    <v-row>
      <v-col cols="12" v-if="showFilters">
        <v-btn class="filterbtn" @click="filtrar"> Filtrar </v-btn>
        <v-btn class="limpar" @click="limpar"> Limpar </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="!isSala && $store.state.uf == 'NA'">
      <v-col>
        <v-radio-group
          v-model="tipoMapa"
          @change="goToMap"
          :hide-details="'auto'"
          class="tipoMapa mb-2"
        >
          <v-radio label="Cidade Empreendedora" :value="'CE'"></v-radio>
          <v-radio label="Território Empreendedor" :value="'TE'"></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
    <component
      :is="dinamicyComponent()"
      v-if="showFilters"
      ref="filtro"
      @changeColor="(e) => $emit('changeColor', e)"
      :filtersDefaultColor="filtersDefaultColor"
    ></component>
    <div>
      <slot name="downloads"></slot>
    </div>
  </div>
</template>
<script>
const FiltrosComponents = {
  FiltroGeral: () => import("./FiltrosGerais.vue"),
  FiltroTerritorio: () => import("./FiltrosTerritorios.vue"),
};

import { mapGetters, mapActions } from "vuex";

export default {
  name: "Filtros",
  props: {
    type: { type: String, default: "FiltroGeral" },
    filtersDefaultColor: { type: Object, default: () => {} },
  },
  data() {
    return {
      showFilters: true,
      tipoMapa: "CE",
    };
  },
  mounted() {
    if (this.$route.name == "dashboard-territorios") {
      this.tipoMapa = "TE";
      this.setTipoMapa("Território Empreendedor");
    }
  },
  computed: {
    ...mapGetters(["isSala"]),
  },
  methods: {
    ...mapActions([
      "setMapaVariables",
      "limparMapaFixo",
      "setLoading",
      "resetarMapaFixo",
      "setTipoMapa",
      "setIsTerritorio",
    ]),
    goToMap(type) {
      if (type == "CE") {
        this.limpar();

        this.setTipoMapa("Cidade Empreendedora");
        this.$router.push("/indicadores/game");
        this.setIsTerritorio(false);
      } else {
        this.limpar();
        this.setIsTerritorio(true);
        this.setTipoMapa("Território Empreendedor");
        this.$router.push("/dashboard-territorios");
      }
    },
    dinamicyComponent() {
      return FiltrosComponents[this.type];
    },
    async filtrar() {
      this.$refs.filtro.filtrar();
    },
    limpar() {
      this.$refs.filtro.limpar();
    },
  },
};
</script>
<style lang="scss" scoped>
.filters {
  position: fixed;
  padding: 20px 0 20px 25px;
  z-index: 2;
  max-width: 380px;
  &.large {
    .filtersSettings > div {
      width: 100%;
    }
  }
}

.tipoMapa {
  margin-top: 0px;
}
</style>
