<template>
  <div>
    <filtros
      @changeColor="(event) => (filtersColor = event)"
      :type="'FiltroTerritorio'"
      :filtersDefaultColor="filtersColor"
    ></filtros>
    <LegendaMapa></LegendaMapa>
    <Mapa :municipios="mapaFixo"></Mapa>
  </div>
</template>
<script>
import filtros from "@/components/mapa/components/Filtro";

import { Mapa } from "@/components/mapa";
import { municipioAPI } from "@/modules/municipio";
import { mapGetters, mapActions } from "vuex";
import LegendaMapa from "@/components/mapa/components/LegendaMapa.vue";
import { defaultMapColour } from "@/components/mapa/constants";

export default {
  name: "MapaView",
  components: {
    Mapa,
    filtros,
    LegendaMapa,
  },
  data() {
    return {
      municipios: [],
      filtersColor: defaultMapColour,
    };
  },
  computed: {
    ...mapGetters(["mapa"]),
    mapaFixo() {
      return this.mapa.mapaFixo;
    },
    filter() {
      return this.mapa.filter;
    },
  },

  methods: {
    ...mapActions([
      "setLoading",
      "setMapaInicial",
      "setMapaVariables",
      "resetarMapaFixo",
    ]),
    isMobileAndTabletCheck() {
      return mobileAndTabletCheck();
    },
    manipulateMunicipio(municipioResponse, territoriosMunicipio) {
      municipioResponse.map((item) => {});
    },
  },
  async mounted() {
    this.setLoading(true);
    try {
      if (localStorage.getItem("mapFiltersColors")) {
        this.filtersColor =
          JSON.parse(localStorage.getItem("mapFiltersColors")) ||
          this.filtersColor;
      } else {
        localStorage.setItem(
          "mapFiltersColors",
          JSON.stringify(this.filtersColor)
        );
      }

      this.resetarMapaFixo();

      const municipioResponse = await municipioAPI.getInfoMunicipio();

      this.municipios = municipioResponse.data;

      this.setMapaInicial(this.municipios);
      this.setMapaVariables({
        mapaFixo: this.municipios,
      });
    } catch (error) {
      console.log("🚀 ~ mounted ~ error:", error);
      this.$notification.error(`Erro ao carregar o mapa`);
    }

    this.setLoading(false);
  },
};
</script>
<style lang="scss">
$mapaRelacionamentoColour: v-bind("filtersColor.relacionamento");
$mapaContratoColour: v-bind("filtersColor.contrato");
$mapaRegionColour: v-bind("filtersColor.region");
$mapaMunicipioColour: v-bind("filtersColor.municipio");
$mapaIndicadorColour: v-bind("filtersColor.indicador");
$mapaUFColour: v-bind("filtersColor.uf");
$mapaEixoColour: v-bind("filtersColor.eixo");

.corMunicipio {
  background: $mapaMunicipioColour;

  polygon {
    fill: $mapaMunicipioColour !important;
  }
}

.corEixo {
  background: $mapaEixoColour;

  polygon {
    fill: $mapaEixoColour !important;
  }
}

.corProjeto {
  background: $mapaMunicipioColour;

  polygon {
    fill: $mapaMunicipioColour !important;
  }
}

.corContrato {
  background: $mapaContratoColour;

  polygon {
    fill: $mapaContratoColour !important;
  }
}

.corRelacionamento {
  background: $mapaRelacionamentoColour;

  polygon {
    fill: $mapaRelacionamentoColour !important;
  }
}

.corUF {
  background: $mapaUFColour;

  polygon {
    fill: $mapaUFColour !important;
  }
}

.corIndicador {
  background: $mapaIndicadorColour;

  polygon {
    fill: $mapaIndicadorColour !important;
  }
}

.corUR {
  background: $mapaRegionColour;

  polygon {
    fill: $mapaRegionColour !important;
  }
}
</style>
